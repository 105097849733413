body {
    margin: 0;
    padding: 0;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  .cropper {
    position: relative;
    height: 50vh;
  }
  
  .viewer {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .output {
    position: relative;
    width: 300px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
  }
  
  .output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
  